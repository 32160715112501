import "./sections.css"

import React from "react"

import { Link } from "gatsby"

const Section1 = ()=>{
    return <section className="adopt-app-sec1">
        <div className="content">
            <h3>Adoption Application</h3>
            <p>There is a <span className="price"><strong>$27.50</strong></span> non-refundable application processing donation. Your application cannot be processed until this is received.</p>
            <p>In order to fill out the application, please click the Apply Now button below.</p>
            <Link className="btn-primary" to="/adoption-application/">Apply Now</Link> 
           
        </div>
    </section>
}

const Sections = {
    Section1
}

export default Sections