import { Link } from "gatsby"
import React from "react"
import Sections from "../components/adoption-process/sections"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HowToHelp from "../components/shared/how-to-help"
import InnerBanner from "../components/shared/inner-banner"
import InnerIntro from "../components/shared/inner-intro"
import banner from "../images/adoption-banner-bg.jpg"
const AdoptionProcessPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Adoption Application" />
    <InnerBanner theme="white" image={banner} title="Adoption Process" />
     <InnerIntro theme="white" title="Adopting a Labrador from DFW Lab Rescue">
       <p>Please make sure you have read and understand DFW Lab Rescue's requirements for "<Link to="/adoption/">Adopting A Lab</Link>". Our adoptions team will closely review your application. We have the right to deny or refuse any application.</p>
       </InnerIntro>
       <Sections.Section1/>
    <HowToHelp />
  </Layout>
}

export default AdoptionProcessPage